const data = [
    {
        Link:
            'http://landscapeurbanism.aaschool.ac.uk/how-do-we-confront-a-world-on-fire-design-and-the-green-new-deal-on-a-warming-planet/',
        Type: 'Lecture',
        Name:
            'How do we confront a world on fire? – Design and The Green New Deal on a Warming Planet',
        Topics: ['gnd', 'climate change', 'economy'],
    },
    {
        Link:
            'https://www.thenation.com/article/archive/socialism-united-states-military/',
        Type: 'Article',
        Name: 'The US Military Is a Socialist Organization',
        Topics: ['military', 'socialism', 'wealth'],
    },
    {
        Link:
            'https://books.google.ru/books?id=sedqDwAAQBAJ&printsec=frontcover&dq=climate+leviathan&hl=ru&sa=X&ved=2ahUKEwii4qnL55nqAhVwxIsKHegcCkYQ6AEwAHoECAEQAg#v=onepage&q&f=false',
        Type: 'Book ',
        Name: [
            'Climate Leviathan: A Political Theory of Our Planetary Future. Geoff Mann',
            'Joel Wainwright',
        ],
        Topics: ['climate change', 'welfare'],
    },
    {
        Link: `https://books.google.ru/books?id=_IFDDwAAQBAJ&printsec=frontcover&dq=people%27s+republic+of+walmart&hl=ru&sa=X&ved=2ahUKEwiyhoD155nqAhUM2aYKHauXBBsQ6AEwAHoECAIQAg#v=onepage&q=people's%20republic%20of%20walmart&f=false`,
        Type: 'Book ',
        Name:
            "The People's Republic of Walmart: How the World's Biggest Corporations are Laying the Foundation for Socialism",
        Topics: ['economy', 'welfare', 'socialism', 'logistics'],
    },
    {
        Link:
            'https://www.igi-global.com/chapter/commercial-logistics-military-logistics/62171',
        Type: 'Article',
        Name:
            'Commercial Logistics vs. Military Logistics: A Conceptual Analysis',
        Topics: ['logistics', 'military', 'economy'],
    },
    {
        Link: 'https://en.wikipedia.org/wiki/Military_settlement',
        Type: 'Map',
        Name: 'Military Settlements',
        Topics: ['history', 'economy'],
    },
    {
        Link:
            'https://www.congress.gov/116/bills/hres109/BILLS-116hres109ih.pdf',
        Type: 'Pdf',
        Name: 'Green New Deal Resolution',
        Topics: ['law', 'climate change', 'policy'],
    },
    {
        Link:
            'https://www.washingtonpost.com/politics/2019/06/26/eisenhower-called-it-military-industrial-complex-its-vastly-bigger-now/',
        Type: 'Article',
        Name:
            'Analysis | Eisenhower called it the ‘military-industrial complex.’ It’s vastly bigger now.',
        Topics: ['theory', 'history', 'economy', 'infrastructure'],
    },
    {
        Link:
            'https://www.independent.co.uk/news/world/asia/china-tree-plant-soldiers-reassign-climate-change-global-warming-deforestation-a8208836.html?amp',
        Type: 'Article',
        Name: [
            'China deploys 60',
            '000 soldiers to plant trees in bid to grow 6 million hectares of new forest this year',
        ],
        Topics: ['climate change', 'military', 'infrastructure', 'disaster'],
    },
    {
        Link:
            'https://www.amazon.com/American-Utopia-Dual-Power-Universal/dp/1784784532',
        Type: 'Book ',
        Name: 'An American Utopia: Dual Power and the Universal Army',
        Topics: ['military', 'policy', 'law'],
    },
    {
        Link: 'https://www.c40.org/other/the-global-green-new-deal',
        Type: 'Website',
        Name: 'Green New Deal C40',
        Topics: ['climate change', 'gnd', 'energy'],
    },
    {
        Link:
            'https://www.amazon.com/Ministry-Future-Kim-Stanley-Robinson-ebook/dp/B084FY1NXB',
        Type: 'Book ',
        Name: 'The Ministry for the Future. Kim Stanley Robinson',
        Topics: ['climate change', 'logistics', 'military', 'economy'],
    },
    {
        Link: 'https://www.dataforprogress.org/gnd-candidates',
        Type: 'Website',
        Name: 'GREEN NEW DEAL: Candidate Scorecards',
        Topics: ['gnd', 'energy', 'policy', 'economy'],
    },
    {
        Link:
            'https://climateandsecurity.files.wordpress.com/2019/07/implications-of-climate-change-for-us-army_army-war-college_2019.pdf',
        Type: 'Report PDF',
        Name: 'Implications of Climate Change for the U.S. Army',
        Topics: ['climate change', 'military', 'disaster', 'economy'],
    },
    {
        Link:
            'https://climateandsecurity.files.wordpress.com/2017/11/working-group-on-climate-nuclear-and-security-affairs_report-one_2017_11_15.pdf',
        Type: 'Report PDF',
        Name: 'Working Group on Climate Nuclear and Security Affairs',
        Topics: ['climate change', 'energy'],
    },
    {
        Link:
            'https://climateandsecurity.files.wordpress.com/2019/01/sec_335_ndaa-report_effects_of_a_changing_climate_to_dod.pdf',
        Type: 'Report PDF',
        Name:
            'Report on Effects of a Changing Climate to the Department of Defense',
        Topics: ['climate change', 'military'],
    },
    {
        Link:
            'https://climateandsecurity.files.wordpress.com/2020/02/world-climate-security-report-2020_2_13.pdf',
        Type: 'Report PDF',
        Name: 'The world climate and security report 2020',
        Topics: ['climate change'],
    },
    {
        Link:
            'https://www.americansecurityproject.org/wp-content/uploads/2012/11/Military-Basing-and-Climate-Change.pdf',
        Type: 'Report PDF',
        Name: 'Military Basing and Climate Change',
        Topics: ['climate change', 'mililtary'],
    },
    {
        Link:
            'https://cdn.locomotive.works/sites/5ab410c8a2f42204838f797e/content_entry5ab410fb74c4833febe6c81a/5ad4d7ee74c4837def5d3d45/files/Deadline_2020_Programme_flyer__August_2017.pdf?1541690065',
        Type: 'Pdf',
        Name: 'C40 DEADLINE 2020 PROGRAMME',
        Topics: ['climate change', 'gnd'],
    },
    {
        Link: 'http://filesforprogress.org/reports/gnd_scorecards/rubric.pdf',
        Type: 'Pdf',
        Name: 'GREEN NEW DEAL RUBRIC FOR 2020 CANDIDATE CLIMATE PLANS',
        Topics: ['climate change', 'policy', 'economy', 'gnd'],
    },
    {
        Link:
            'https://www.iea.org/data-and-statistics/charts/projected-change-in-primary-energy-demand-by-fuel-in-2020-relative-to-2019',
        Type: 'Report',
        Name:
            'Projected change in primary energy demand by fuel in 2020 relative to 2019',
        Topics: ['climate change', 'energy'],
    },
    {
        Link:
            'https://westerncaucus.house.gov/uploadedfiles/costs_of_the_green_new_deal.pdf',
        Type: 'Pdf',
        Name: 'Green New Deal Costs',
        Topics: ['climate change', 'gnd'],
    },
    {
        Link:
            'https://www.history.com/topics/21st-century/military-industrial-complex',
        Type: 'Article',
        Name: 'Military Industrial Complex',
        Topics: ['history', 'industry', 'military', 'service'],
    },
    {
        Link: 'https://apps.dtic.mil/dtic/tr/fulltext/u2/1047830.pdf',
        Type: 'Report PDF',
        Name: 'Climate change and Policy',
        Topics: ['climate change'],
    },
    {
        Link: 'https://www.youtube.com/watch?v=bsqO6ADTDKc',
        Type: 'Video',
        Name:
            "Eisenhower's Military-Industrial Complex Speech in the Age of Coronavirus",
        Topics: ['military', 'industry'],
    },
    {
        Link:
            'https://www.thedailybeast.com/google-co-founder-sergey-brin-has-a-secret-disaster-relief-squad?utm_source=nextdraft&utm_medium=email',
        Type: 'Article',
        Name:
            'Global Support and Development is made up of ex-military men and Brin’s former bodyguards. But their mission isn’t warfare.',
        Topics: ['disaster', 'military'],
    },
    {
        Link:
            'https://books.google.ru/books?id=0DdcDwAAQBAJ&lpg=PP1&dq=All%20Hell%20Breaking%20Loose%3A%20The%20Pentagon%27s%20Perspective%20on%20Climate%20Change%2C%20Michael%20T.%20Klare&hl=ru&pg=PT4#v=onepage&q&f=false',
        Type: 'Book ',
        Name: [
            "All Hell Breaking Loose: The Pentagon's Perspective on Climate Change",
            'Michael T. Klare',
        ],
        Topics: ['climate change', 'military', 'forecast'],
    },
    {
        Link:
            'https://books.google.ru/books?id=OWknDQAAQBAJ&lpg=PP1&dq=Nature%20is%20a%20Battlefield%2C%20Razmig%20Keucheyan&hl=ru&pg=PP1#v=onepage&q=Nature%20is%20a%20Battlefield,%20Razmig%20Keucheyan&f=false',
        Type: 'Book ',
        Name: ['Nature is a Battlefield', 'Razmig Keucheyan'],
        Topics: ['disaster', 'climate change'],
    },
    {
        Link:
            'http://s3.amazonaws.com/arena-attachments/956312/ae0bcf422dbea46cc47134b0098c7dd7.pdf?1491075792=',
        Type: 'Article',
        Name: ['Figures of Destructuration', 'Benjamin H Bratton'],
        Topics: ['terrorism', 'architecture'],
    },
    {
        Link: 'https://journals.sagepub.com/doi/abs/10.1177/1942778620918041',
        Type: 'Article',
        Name: [
            'Bureaucratic land grabbing for infrastructural colonization: renewable energy',
            'L’Amassada',
            'and resistance in southern France',
            'Alexander Dunlap',
        ],
        Topics: ['climate change', 'infrastructure', 'energy'],
    },
    {
        Link: 'https://journals.sagepub.com/doi/abs/10.1177/0047117819879482',
        Type: 'Article',
        Name: [
            'On the way to planet politics: From disciplinary demise to cosmopolitical coordination',
            'Philip R Conway',
        ],
        Topics: ['anthropocene', 'policy', 'economy'],
    },
    {
        Link:
            'https://criticaltheoryconsortium.org/wp-content/uploads/2018/05/Benjamin-Critique-of-Violence-1.pdf',
        Type: 'Article',
        Name: 'Critique of Violence Walter Benjamin',
        Topics: ['theory', 'law'],
    },
    {
        Link: 'https://ips-dc.org/wp-content/uploads/2016/09/CvsC-Report-1.pdf',
        Type: 'Report',
        Name:
            'Combat vs Climate: The Military and Security Budgets Compared Miriam Pemberton Ellen Powell Nathan Doctor',
        Topics: ['economy', 'climate change', 'military'],
    },
    {
        Link:
            'https://climateandsecurity.files.wordpress.com/2017/06/epicenters-of-climate-and-security_the-new-geostrategic-landscape-of-the-anthropocene_2017_06_091.pdf',
        Type: 'Book ',
        Name:
            'Epicenters of Climate and Security: the New Geostrategic Landscape of the Anthropocene',
        Topics: ['climate change', 'energy', 'health'],
    },
    {
        Link: 'https://science.sciencemag.org/content/162/3859/1243',
        Type: 'Article',
        Name: 'The Tragedy of the Commons Garett Hardin',
        Topics: ['economy', 'socialism'],
    },
    {
        Link:
            'https://books.google.ru/books?id=ZQt6DQAAQBAJ&lpg=PP1&dq=Landscape%20as%20Infrastructure%3A%20a%20Base%20Primer%2C%20Pierre%20Belanger&hl=ru&pg=PP1#v=onepage&q=Landscape%20as%20Infrastructure:%20a%20Base%20Primer,%20Pierre%20Belanger&f=false',
        Type: 'Book ',
        Name: 'Landscape as Infrastructure: a Base Primer Pierre Belanger',
        Topics: ['architecture', 'infrastructure', 'logistics'],
    },
    {
        Link:
            'https://books.google.ru/books?id=Nb8pDwAAQBAJ&lpg=PP1&dq=Nothing%20is%20true%20and%20everything%20is%20possible%3A%20a%20surreal%20heart%20of%20modern%20Russia%2C%20Peter%20Pomerantsev&hl=ru&pg=PP1#v=onepage&q&f=false',
        Type: 'Book ',
        Name:
            'Nothing is true and everything is possible: a surreal heart of modern Russia, Peter Pomerantsev',
        Topics: ['law', 'policy', 'economy'],
    },
    {
        Link:
            'https://democracycollaborative.org/learn/collections/ownership-futures',
        Type: 'Pamphlet',
        Name: 'Ownership Futures, Thomas M. Hanna, Mathew Lawrence',
        Topics: ['law', 'policy'],
    },
    {
        Link:
            'https://www.sipri.org/publications/2017/working-paper/national-climate-related-security-policies-permanent-member-states-united-nations-security-council',
        Type: 'Working paper',
        Name:
            'National Climate-Related Security Policies of the Permanent Member States of the United Nations Security Council, Jiayi Zhou',
        Topics: ['climate change', 'policy'],
    },
    {
        Link:
            'https://books.google.ru/books?id=VePaDwAAQBAJ&lpg=PP1&dq=Territory%20Beyond%20Terra&hl=ru&pg=PP1#v=onepage&q=Territory%20Beyond%20Terra&f=false',
        Type: 'Book ',
        Name: 'Territory Beyond Terra',
        Topics: ['law', 'geography'],
    },
    {
        Link:
            'https://books.google.ru/books?id=Mj87CQAAQBAJ&lpg=PP1&dq=Power%20Density%3A%20a%20Key%20to%20Understanding%20Energy%20Sources%20and%20Uses%2C%20Vaclav%20Smil&hl=ru&pg=PP1#v=onepage&q=Power%20Density:%20a%20Key%20to%20Understanding%20Energy%20Sources%20and%20Uses,%20Vaclav%20Smil&f=false',
        Type: 'Book ',
        Name:
            'Power Density: a Key to Understanding Energy Sources and Uses, Vaclav Smil',
        Topics: ['energy', 'climate change'],
    },
    {
        Link:
            'https://climateandsecurity.org/the-responsibility-to-prepare-and-prevent-a-climate-security-governance-framework-for-the-21st-century/',
        Type: 'Report',
        Name:
            'The Responsibility to Prepare and Prevent: a Climate Security Governance Framework for the 21st Century, Caitlin Werell, Francesko Femia',
        Topics: ['climate change', 'security', 'policy'],
    },
    {
        Link:
            'http://www.disastergovernance.net/fileadmin/gppi/RTB_book_chp22.pdf',
        Type: 'Book Chapter',
        Name:
            'Civil–Military Relations in Hurricane Katrina: A Case Study on Crisis Management in Natural Disaster Response, Jean- Loup Samaan, Laurent Verneuil',

        Topics: ['climate change', 'disaster', 'military'],
    },
    {
        Link: 'https://mitpress.mit.edu/books/rare-earth',
        Type: 'Book',
        Name: 'Rare Earth',
        Topics: ['infrastructure', 'resources'],
    },
    {
        Link:
            'https://www.airuniversity.af.edu/Portals/10/ASPJ_French/journals_E/Volume-07_Issue-3/scottkhan_e.pdf',
        Type: 'Article',
        Name:
            'The implications of Climate Change for the Military and for Conflict Prevention, Including through Peace Missions, Shirley V. Scott, Shahedul Khan',
        Topics: ['climate change', 'military'],
    },
    {
        Link:
            'https://books.google.ru/books?id=5zB0DwAAQBAJ&lpg=PP1&dq=The%20Anarchist%20Roots%20of%20Geography%3A%20Towards%20Spatial%20Emancipation&hl=ru&pg=PP1#v=onepage&q=The%20Anarchist%20Roots%20of%20Geography:%20Towards%20Spatial%20Emancipation&f=false',
        Type: 'Book',
        Name: 'The Anarchist Roots of Geography: Towards Spatial Emancipation',
        Topics: ['geography'],
    },
    {
        Link:
            'https://books.google.ru/books?id=_OsaKlegTbcC&lpg=PP1&dq=The%20Exploit%3A%20a%20Theory%20of%20Networks%2C%20A.%20Galloway%2C%20E.%20Thacker&hl=ru&pg=PP1#v=onepage&q=The%20Exploit:%20a%20Theory%20of%20Networks,%20A.%20Galloway,%20E.%20Thacker&f=false',
        Type: 'Book',
        Name: 'The Exploit: a Theory of Networks, A. Galloway, E. Thacker',
        Topics: ['theory'],
    },
    {
        Link:
            'https://www.rsis.edu.sg/rsis-publication/rsis/the-new-rare-metal-age-new-challenges-and-implications-of-critical-raw-materials-supply-security-in-the-21st-century/',
        Type: 'Article',
        Name:
            'The New  Rare Metal Age : New Challenges and Implications of Critical Raw Materials Supply Security in the 21th Century, Frank Umbach',
        Topics: ['resources', 'geography'],
    },
    {
        Link:
            'https://books.google.ru/books?id=IC6VDwAAQBAJ&lpg=PP1&dq=This%20is%20Not%20a%20Drill%3Aan%20Extinction%20Rebellion%20Handbook&hl=ru&pg=PP1#v=onepage&q=This%20is%20Not%20a%20Drill:an%20Extinction%20Rebellion%20Handbook&f=false',
        Type: 'Book',
        Name: 'This is Not a Drill:an Extinction Rebellion Handbook',
        Topics: ['climate change', 'policу'],
    },
    {
        Link:
            'https://books.google.ru/books?id=hAyGDwAAQBAJ&lpg=PT171&dq=Politics%20of%20the%20Anthropocene%3A%20Formation%20of%20the%20Commons%20as%20a%20Geologic%20Process%2C%20Kathryn%20Yusoff&hl=ru&pg=PT171#v=onepage&q=Politics%20of%20the%20Anthropocene:%20Formation%20of%20the%20Commons%20as%20a%20Geologic%20Process,%20Kathryn%20Yusoff&f=false',
        Type: 'Article',
        Name:
            'Politics of the Anthropocene: Formation of the Commons as a Geologic Process, Kathryn Yusoff',
        Topics: ['anthropocene', 'policy'],
    },
    {
        Link: 'https://www.todaysmilitary.com/military-life/housing',
        Type: 'Website',
        Name: 'Todaysmilitary',
        Topics: ['military', 'welfare', 'service'],
    },
]

export default data
