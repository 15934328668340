/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import { Img } from '../../components'

const Video = () => {
    const [isClicked, toggleClicked] = useState(false)
    const handleToggle = () => {
        toggleClicked(!isClicked)
    }
    return (
        <iframe
            title="video"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/_U2JksnJ_NI?list=PL1DbqwJKQOEX3QwtjwMkMBM7kP4Hg7goy"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    )
}

export default Video
