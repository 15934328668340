const filters = [
    {
        ancor: 'carbon-dioxide',
        text: 'Prologue',
    },
    {
        ancor: 'what-is-the-green-new-deal',
        text: 'The Plan',
    },
    {
        ancor: 'who-could-be-the-potential',
        text: 'The Candidate',
    },
    {
        ancor: 'possible-scenarious',
        text: 'The Post-Military',
    },
]

export default filters
