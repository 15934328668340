import styled from '@emotion/styled'
import {
    typography,
    space,
    color,
    layout,
    position,
    background,
} from 'styled-system'

const BookCover = styled('img')(
    typography,
    layout,
    space,
    color,
    position,
    background
)

export default BookCover
