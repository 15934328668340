/* eslint-disable import/prefer-default-export */

import styled from '@emotion/styled'
import Text from './Text'

const RotatedText = styled(Text)({
    '@media (min-width: 600px)': {
        marginTop: '36px',
        writingMode: 'vertical-lr',
        textAlign: 'right',
        transform: 'rotate(180deg)',
    },
})

export default RotatedText
