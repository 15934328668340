/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { BookCover, Div, Flex, Text } from '../../components'

const books = [
    'https://storage.strelka.com/i/b9c4a452-6d86-4865-b10e-4036964a34c2',
    'https://storage.strelka.com/i/c5b75e09-5911-4e8d-87cd-3c77f0975fa1',
    'https://storage.strelka.com/i/1dcd824f-fb3c-4d2f-b537-1f5398a6e367',
    'https://storage.strelka.com/i/80f27985-fb16-4348-873a-d7ea34e3f9d1',
    'https://storage.strelka.com/i/c91c78e0-cbc1-43f4-8317-6b535bf1399a',
    'https://storage.strelka.com/i/99532fad-92a3-41be-b604-31c20aa76223',
    'https://storage.strelka.com/i/529aa2be-8b1c-42e5-868b-95e61fa54d44',
    'https://storage.strelka.com/i/27b34968-817a-455c-b42b-adffcc5fa874',
    'https://storage.strelka.com/i/b986a526-02b9-4992-9203-a0c59e076527',
    'https://storage.strelka.com/i/6fdbbb84-2f6e-452d-bbf9-06cb85773b40',
    'https://storage.strelka.com/i/de68fed7-7b78-4ec7-9b81-64aca94962c6',
    'https://storage.strelka.com/i/5537a312-8356-4eae-a563-f54888d56261',
    'https://storage.strelka.com/i/39387485-0471-4a15-b933-2a1911bf0883',
    'https://storage.strelka.com/i/d323761b-1af0-48c0-bc7b-6388b748bf52',
    'https://storage.strelka.com/i/20cfdefe-215a-42d8-b7ae-5906c8b51eb5',
    'https://storage.strelka.com/i/7c89e04d-935b-4138-9735-a1daeb96ff70',
    'https://storage.strelka.com/i/ba03360c-eebd-42fa-bd8e-47fb03b1d2cf',
    'https://storage.strelka.com/i/5d584196-873e-4fe0-b905-7b4577f7ff42',
    'https://storage.strelka.com/i/45589953-36c0-4eca-99b3-52cefb8309a9',
    'https://storage.strelka.com/i/9ad10188-f2b4-4dd5-8c9f-fd053890741e',
]

class BookGallery extends PureComponent {
    constructor(props) {
        super(props)
        this.handleToggle = this.handleToggle.bind(this)
        this.state = { arr: [], isToggled: false }
    }

    componentDidMount() {
        if (
            typeof window.orientation !== 'undefined' ||
            navigator.userAgent.indexOf('IEMobile') !== -1
        ) {
            this.setState({ isToggled: true })
        }

        const arr = [0]
        for (let index = 0; arr.length < 10; index++) {
            const indx = Math.floor(Math.random() * Math.floor(books.length))
            if (!arr.includes(indx)) {
                arr.push(indx)
            }
        }

        this.setState({ arr })
    }

    handleToggle() {
        this.setState({ isToggled: !this.state.isToggled })
    }

    render() {
        const { arr, isToggled } = this.state
        return (
            <Div size="100%" position="relative">
                {arr.map((book, index) => {
                    return index !== 10 ? (
                        <BookCover
                            key={book}
                            width={[200, 300, 400]}
                            style={{
                                boxShadow: `-10px 0px 30px 0px rgba(0,0,0,${
                                    isToggled ? 0.1 : 0
                                })`,
                                position: 'absolute',
                                right: isToggled
                                    ? `calc(70% - ${7 * (9 - index)}%)`
                                    : '70px',
                                transition: 'right 1s, box-shadow 1s',
                                bottom: '-40px',
                                willChange: 'right, box-shadow',
                                zIndex: 10 - index,
                            }}
                            alt="black-book-cover"
                            src={books[book]}
                        />
                    ) : null
                })}
                <Flex
                    onMouseEnter={this.handleToggle}
                    onMouseLeave={this.handleToggle}
                    style={{
                        position: 'absolute',
                        right: '70px',
                        bottom: '-40px',
                        zIndex: 50,
                    }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text position="absolute" fontSize="20px">
                        <a
                            target="__blank"
                            rel="noopener"
                            href="http://gmnd.org/GMND.pdf"
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                opacity: isToggled ? 1 : 0,
                                transition: 'opacity 1s',
                            }}
                        >
                            Download
                        </a>
                    </Text>
                    <BookCover
                        width={[200, 300, 400]}
                        style={{
                            boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.75)',
                        }}
                        alt="black-book-cover"
                        src="https://storage.strelka.com/i/761cc7c4-ee3a-44af-b768-fb97e9685195"
                    />
                </Flex>
            </Div>
        )
    }
}

export default BookGallery
