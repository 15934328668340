import styled from '@emotion/styled'
import {
    flexbox,
    size,
    layout,
    position,
    color,
    border,
    space,
} from 'styled-system'

const DataRow = styled('tr')(
    flexbox,
    layout,
    position,
    color,
    border,
    size,
    space,
    {
        padding: 0,
        borderBottom: '1px solid',
        cursor: 'pointer',
        // transition: 'color .1s, background-color .1s',
        ':hover': {
            color: 'white',
            backgroundColor: 'black',
        },
    }
)

export default DataRow
