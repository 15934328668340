/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Scrollbars } from 'react-custom-scrollbars'
import { Tab, withVh } from '../../hocs'
import { Img, Text, Div, Flex } from '../../components'
import Video from './video'
import filters from './filters'

const GobernanzaM = ({ isActiveTab, active, index, onRotate }) => {
    const handleScrollTo = (event) => {
        const el = document.getElementById(event.target.dataset.ancor)
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
    }
    return (
        <Tab
            isActiveTab={isActiveTab}
            active={active}
            index={index}
            onRotate={onRotate}
            title="REPORT"
        >
            <Div
                backgroundColor="#EAEAEA"
                zIndex="100"
                position="sticky"
                top="0"
                wordBreak="break-word"
            >
                <Div
                    ml={['20px', '90px', '240px']}
                    mr={['20px', '90px', '240px']}
                    maxWidth="1900px"
                    pt={['15px', '25px', '25px']}
                    pb={['20px', '30px', '30px']}
                >
                    <Text
                        textAlign="left"
                        display="block"
                        width="100%"
                        fontSize={['15px', '22px', '22px']}
                    >
                        {filters.map((filter) => (
                            <Text
                                mx="6px"
                                style={{ cursor: 'pointer' }}
                                cursor="pointer"
                                key={filter.ancor}
                                data-ancor={filter.ancor}
                                onClick={handleScrollTo}
                            >
                                {filter.text}
                            </Text>
                        ))}
                    </Text>
                </Div>
            </Div>
            <Scrollbars>
                <Div
                    ml={['20px', '90px', '240px']}
                    mr={['20px', '110px', '260px']}
                    maxWidth="1900px"
                    pb="80px"
                    flexDirection="column"
                >
                    <Text
                        fontWeight="bold"
                        fontSize={['25px', '50px', '50px']}
                        pb="40px"
                        display="block"
                    >
                        Green Military New Deal
                    </Text>
                    <Div>
                        <Flex height={[300, 450, 550]} justifyContent="center">
                            <Video />
                        </Flex>
                    </Div>
                </Div>
                <Div
                    pt={['30px', '100px', '100px']}
                    pb="120px"
                    id="carbon-dioxide"
                    backgroundColor="white"
                >
                    <Div
                        ml={['20px', '90px', '240px']}
                        mr={['20px', '110px', '260px']}
                        maxWidth="1900px"
                    >
                        <Text
                            fontWeight="bold"
                            fontSize={['25px', '50px', '50px']}
                        >
                            Governance — Carbon Dioxide
                        </Text>
                        <Div pt={['20px', '40px', '40px']}>
                            <Text fontSize={['15px', '22px', '22px']}>
                                Carbon makes up only about 0.025 percent of
                                Earth&apos;s crust — yet it forms more compounds
                                than all the other elements combined. Its
                                transformation represents a biogeochemical
                                process that moves thousands of millions of tons
                                of this element every year.
                                <br />
                                This carbon cycle constitutes a sequence of
                                events which are key to Earth’s capability of
                                sustaining life. It has been operating and
                                fluctuating for millions of years. During the
                                last two centuries, human activity has severely
                                altered this cycle, producing a metabolic
                                disequilibrium.
                            </Text>
                        </Div>
                        <Div pt="50px" pl={['auto', '80px', '80px']}>
                            <Div position="relative">
                                <Img
                                    width={['100%', '35vw', '35vw']}
                                    style={{
                                        alignSelf: 'center',
                                        marginTop: '50px',
                                    }}
                                    src="https://storage.strelka.com/i/68ff6fa5-893c-481d-a0ec-c7479e388f0b"
                                    alt="video"
                                />
                                <Text
                                    position={[
                                        'inherit',
                                        'absolute',
                                        'absolute',
                                    ]}
                                    ml={['auto', '20px', '20px']}
                                    mr={['auto', '60px', '60px']}
                                    bottom={['0px', '-70px', '-70px']}
                                    fontWeight="bold"
                                    fontSize={['15px', '30px']}
                                >
                                    407 parts per million, this is the current
                                    concentration of carbon dioxide in
                                    Earth&apos;s atmosphere, which represents a
                                    47 percent increase since the beginning of
                                    the Industrial Age
                                </Text>
                            </Div>
                        </Div>
                    </Div>
                </Div>
                <Div width="100%" pt={['30px', '100px', '100px']}>
                    <Div
                        ml={['20px', '90px', '240px']}
                        position="relative"
                        zIndex={2}
                        mr={['20px', '110px', '260px']}
                        maxWidth="1900px"
                    >
                        <Text
                            fontWeight="bold"
                            fontSize={['25px', '50px', '50px']}
                            id="tipping-points"
                        >
                            Governance — Tipping Points
                        </Text>
                        <Div pt={['20px', '40px', '40px']}>
                            <Text fontSize={['15px', '22px', '22px']}>
                                Thousands of scientists and experts continue
                                proclaiming the myriad of disastrous
                                implications of surpassing the 1.5 degrees
                                barrier. The sequence of several climatic
                                disasters such as recurring floods, droughts,
                                desertification, or wildfires will not only
                                cause direct damage but also lead to chains of
                                catastrophic events such as massive migration,
                                sociopolitical instability, pandemics, or even
                                open armed conflicts that will cost trillions of
                                dollars and millions of lives.
                            </Text>
                        </Div>
                        <Div
                            pb={['50px', '100px']}
                            pt="50px"
                            px={['auto', '80px', '80px']}
                        >
                            <Flex justifyContent="center">
                                <Img
                                    style={{
                                        width: '100%',
                                        alignSelf: 'center',
                                    }}
                                    src="https://storage.strelka.com/i/00c950a9-9182-4caa-8f6d-de425d00758c"
                                    alt="Governance — Tipping Points"
                                />
                            </Flex>
                        </Div>
                        <Text
                            fontWeight="bold"
                            fontSize={['25px', '50px', '50px']}
                            id="governance-issue"
                        >
                            Governance — Governance Issue
                        </Text>
                        <Div pt={['20px', '40px', '40px']}>
                            <Text fontSize={['15px', '22px', '22px']}>
                                If climate change is itself a result of
                                inappropriate infrastructural management, of
                                both natural and anthropogenic infrastructures,
                                then human beings are not only capable of
                                altering climate conditions but are able to
                                organise new terraforming process that will be
                                deliberately planned and managed to counter the
                                ongoing “accidental” one. Among the most serious
                                problems that this plan faces remains the lack
                                of proper planetary ecosystem governance.
                                Climate change challenges the post-Westphalian
                                system of sovereignty, which does not seem to be
                                able to manage planetary metabolism, that goes
                                beyond the territorial boundaries of
                                nation-states.
                            </Text>
                        </Div>
                        <Div pt="50px" px={['auto', '80px', '80px']}>
                            <Flex justifyContent="center">
                                <Img
                                    style={{
                                        alignSelf: 'center',
                                        width: '100%',
                                    }}
                                    src="https://storage.strelka.com/i/df28fc35-50d2-4486-8d29-5ae2802a96d2"
                                    alt="Governance — Governance Issue"
                                />
                            </Flex>
                        </Div>
                        <Flex
                            mt={['100px', '165px']}
                            pl={['auto', '80px', '80px']}
                        >
                            <Flex flex="1.6">
                                <Div>
                                    <Img
                                        style={{
                                            width: '100%',
                                            alignSelf: 'center',
                                        }}
                                        src="https://storage.strelka.com/i/7b6f0b41-e3e8-4419-8ebc-8ebc23d4d2f7"
                                        alt="Recently several institutions, both national
                                        and international ones have published a
                                        series of measures trying to face this lack
                                        of ecological agency. These proto-policies
                                        are branded under the name of Green New
                                        Deals and the outcome of their proposal
                                        means a whole reconfiguration of society,
                                        economy, and productive forces of labor."
                                    />
                                </Div>
                            </Flex>
                            <Flex flex="3">
                                <Text
                                    mt="-40px"
                                    ml="30px"
                                    fontWeight="bold"
                                    fontSize={['12px', '30px', '30px']}
                                >
                                    Recently several institutions, both national
                                    and international ones have published a
                                    series of measures trying to face this lack
                                    of ecological agency. These proto-policies
                                    are branded under the name of Green New
                                    Deals and the outcome of their proposal
                                    means a whole reconfiguration of society,
                                    economy, and productive forces of labor.
                                </Text>
                            </Flex>
                        </Flex>
                    </Div>
                    <Div
                        size="100%"
                        mt={['auto', '-100px', '-100px']}
                        overflow="hidden"
                    >
                        <Img
                            height={['200px', 'auto', 'auto']}
                            src="https://storage.strelka.com/i/d257918d-bebc-455a-b728-5e42af54fac6"
                            alt="map"
                        />
                    </Div>
                    <Div
                        size="100%"
                        pt={['30px', '100px', '100px']}
                        backgroundColor="white"
                        id="what-is-the-green-new-deal"
                    >
                        <Div
                            ml={['20px', '90px', '240px']}
                            position="relative"
                            zIndex={2}
                            mr={['20px', '110px', '260px']}
                            maxWidth="1900px"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                            >
                                Plan — What is the Green New Deal?
                            </Text>
                            <Div pt={['20px', '40px', '40px']}>
                                <Text fontSize={['15px', '22px', '22px']}>
                                    The name refers back to the American New
                                    Deal, a set of social and economic reforms
                                    to counter the Great Depression of the
                                    1930s. The additional term “Green” refers to
                                    the necessity to include ecological
                                    administration as an essential task for
                                    which the State should be responsible.
                                    Despite the peculiarity of the different
                                    Green New Deals, the most important basic
                                    premise that they all share is a fundamental
                                    shift in the logic of federal sovereignty
                                    from the governance of societies to the
                                    governance of ecosystems. This is a huge
                                    shift in the very definition of the
                                    political as such, and implies to understand
                                    ecosystems as complexly interlinked entities
                                    that include human societies entangled among
                                    other ecologies.
                                </Text>
                            </Div>
                            <Div
                                py={['30px', '100px', '100px']}
                                px={['auto', '80px', '80px']}
                            >
                                <Flex justifyContent="center">
                                    <Img
                                        width={['100%', '440px', '440px']}
                                        style={{
                                            alignSelf: 'center',
                                        }}
                                        src="https://storage.strelka.com/i/46f0d4fc-1fe8-4cc1-adbc-92db353a5314"
                                        alt="Recently, the number of these green policies
                                        have been growing as a result of the increasing
                                        level of awareness in relation to climate change
                                        issues. They have been promoted by several
                                        national, transnational and supranational
                                        entities like the C40 or the EU, but due to the
                                        lack of real jurisdictional agency, none of
                                        these proposals has been fully implemented"
                                    />
                                </Flex>
                            </Div>
                            <Text fontSize={['15px', '22px', '22px']}>
                                Recently, the number of these green policies
                                have been growing as a result of the increasing
                                level of awareness in relation to climate change
                                issues. They have been promoted by several
                                national, transnational and supranational
                                entities like the C40 or the EU, but due to the
                                lack of real jurisdictional agency, none of
                                these proposals has been fully implemented
                            </Text>
                            <Div pt="50px" backgroundColor="white">
                                <Img
                                    src="https://storage.strelka.com/i/90b5b516-f98a-455b-9670-933a8ae143fa"
                                    alt="graph"
                                    style={{
                                        alignSelf: 'center',
                                        maxWidth: '1920px',
                                        width: '100%',
                                        backgroundColor: 'white',
                                    }}
                                />
                            </Div>
                        </Div>
                    </Div>
                    <Div
                        size="100%"
                        pt={['30px', '100px', '100px']}
                        backgroundColor="white"
                    >
                        <Div
                            ml={['20px', '90px', '240px']}
                            position="relative"
                            zIndex={2}
                            mr={['20px', '110px', '260px']}
                            maxWidth="1900px"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                                id="comparative-analyse-of-green-new-deals"
                            >
                                Plan — Comparative analyse of Green New Deals
                            </Text>
                            <Div pt={['20px', '40px', '40px']}>
                                <Text fontSize={['15px', '22px', '22px']}>
                                    A comparative analysis of Green New Deals
                                    shows that even though they all seek to
                                    redirect governance towards ecosystem
                                    control, through cataloguing a wide array of
                                    similar political measures, they may differ
                                    substantially from each other in the scope,
                                    scale, enforcement, and the socioeconomic
                                    paradigm where they operate.
                                </Text>
                            </Div>
                            <Flex maxWidth="1900px" justifyContent="center">
                                <Img
                                    width={['100%', '900px', '900px']}
                                    style={{
                                        alignSelf: 'center',
                                        marginTop: '50px',
                                    }}
                                    src="https://storage.strelka.com/i/b7945f97-dfa1-4f0d-adac-3af7a6c38bf0"
                                    alt="Plan — Comparative analyse of Green New Deals"
                                />
                            </Flex>
                        </Div>
                    </Div>
                    <Div size="100%" pt={['30px', '100px', '100px']}>
                        <Div
                            ml={['20px', '90px', '240px']}
                            position="relative"
                            zIndex={2}
                            mr={['20px', '110px', '260px']}
                            maxWidth="1900px"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                                id="problem-solving-mechanism"
                            >
                                Plan — Problem-Solving Mechanism
                            </Text>
                            <Div pt={['20px', '40px', '40px']}>
                                <Flex justifyContent="center" pb="50px">
                                    <Img
                                        style={{
                                            alignSelf: 'center',
                                            width: '100%',
                                        }}
                                        src="https://storage.strelka.com/i/fa7f4ac0-e8b5-44bc-8f9b-7448bf49df20"
                                        alt="Plan — Problem-Solving Mechanism"
                                    />
                                </Flex>
                                <Flex mb="160px">
                                    <Text
                                        fontSize={['15px', '22px', '22px']}
                                        flex="1"
                                        pr="13px"
                                    >
                                        To frame the Green New Deal as a
                                        governance problem-solving mechanism
                                        means to understand the limitations that
                                        these proto-policies encounter from a
                                        legislative point of view. A Green New
                                        Deal is not a Bill nor a legislation,
                                        it’s not even a policy proposal, it is
                                        nothing that any jurisdictional organism
                                        can pass and make law. They are just the
                                        first step. These guides frame a common
                                        understanding of the problem and settle
                                        a set of goals aimed to avoid global
                                        disaster, but they do not set the
                                        methods of achieving those goals nor do
                                        they picture the consequences of
                                        accomplishing them.
                                    </Text>
                                    <Text flex={['0', '0.1', '0.1']} />
                                    <Text
                                        fontSize={['15px', '22px', '22px']}
                                        flex="1"
                                    >
                                        For example, Reaching Zero Net carbon
                                        metabolism between 2030 and 2050 implies
                                        reshaping a whole network of industries
                                        directly connected to the fossil fuel
                                        industry: rethinking the transportation
                                        systems, transforming the building
                                        industry towards new efficiency
                                        standards, the reorganization and
                                        decarbonization of the supply chains or
                                        a radical shift in the energy production
                                        and distribution networks for instance.
                                        The transition to a new energy regime
                                        does not just mean displacing the fossil
                                        fuel sector from the global economy, but
                                        more importantly, reskilling and
                                        reorienting an entire workforce toward
                                        new means, creating a whole economy
                                        based on investment and developing the
                                        necessary technologies for the
                                        post-fossil fuel sector.
                                    </Text>
                                </Flex>
                                <Flex
                                    px={['auto', '80px', '80px']}
                                    flexDirection="row"
                                    mb="220px"
                                >
                                    <Flex flex="1.4">
                                        <Img
                                            style={{
                                                width: '100%',
                                                alignSelf: 'center',
                                            }}
                                            src="https://storage.strelka.com/i/3b922a74-d172-477a-9ba9-3c03027fa440"
                                            alt="This is, by all means, a huge and
                                            remarkable task, that if not well
                                            managed, could lead to a huge social
                                            and economical breakdown."
                                        />
                                    </Flex>
                                    <Flex
                                        alignSelf="flex-end"
                                        flex="1"
                                        ml="20px"
                                        mb="-90px"
                                    >
                                        <Text
                                            fontWeight="bold"
                                            fontSize={['15px', '30px']}
                                        >
                                            This is, by all means, a huge and
                                            remarkable task, that if not well
                                            managed, could lead to a huge social
                                            and economical breakdown.
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Div>
                        </Div>
                    </Div>
                    <Div
                        size="100%"
                        pt={['30px', '100px', '100px']}
                        backgroundColor="white"
                    >
                        <Div
                            ml={['20px', '90px', '240px']}
                            position="relative"
                            zIndex={2}
                            mr={['20px', '110px', '260px']}
                            maxWidth="1900px"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                                id="mixed-economy"
                            >
                                Plan — Mixed economy
                            </Text>
                            <Div pt={['20px', '40px', '40px']}>
                                <Text
                                    fontSize={['15px', '22px', '22px']}
                                    display="inline-block"
                                    mb={['60px', '120px', '120px']}
                                >
                                    But this is why the Green New Deals also
                                    incorporate a social and economical
                                    dimension to the infrastructural policies
                                    they propose. To understand the magnitude of
                                    this radical infrastructural shift means to
                                    comprehend how societies, and the economic
                                    processes driving them, are deeply entangled
                                    with ecosystem management. Therefore, social
                                    security and economic stability must be
                                    provided during the transition period. This
                                    materializes in a set of promises aiming to
                                    avoid the recoil of moving away from fossil
                                    fuels while guaranteeing social rights and
                                    equality among the most vulnerable
                                    communities. Job and housing guarantees,
                                    social and economic benefits, public health
                                    care systems, free access to education and
                                    training... These are basic elements of
                                    economic freedom ought to be promised to
                                    every citizen in the world, and are the only
                                    means through which a viable transition can
                                    be accomplished. However, the way these
                                    social warrants could be achieved remains
                                    mostly unspecified.
                                </Text>
                                <Flex
                                    flexDirection={['column', 'row', 'row']}
                                    px={['0px', '80px', '80px']}
                                >
                                    <Flex flex="0.8">
                                        <Img
                                            style={{
                                                width: '100%',
                                                alignSelf: 'center',
                                            }}
                                            src="https://storage.strelka.com/i/ebbd498c-023d-407f-9026-aec27b631d36"
                                            alt="To fulfil these goals means to adopt
                                            a mixed economic model that
                                            incorporates elements of a
                                            free-market economy and a planned
                                            economy, including broader social
                                            and ecological considerations within
                                            the neoliberal market."
                                        />
                                    </Flex>
                                    <Flex flex="1" ml="20px">
                                        <Text
                                            fontWeight="bold"
                                            fontSize={['15px', '36px', '36px']}
                                        >
                                            To fulfil these goals means to adopt
                                            a mixed economic model that
                                            incorporates elements of a
                                            free-market economy and a planned
                                            economy, including broader social
                                            and ecological considerations within
                                            the neoliberal market.
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Div>
                            <Text
                                mt="100px"
                                display="inline-block"
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                                id="hypothetical-organization"
                            >
                                Plan — Hypothetical Organization
                            </Text>
                            <Div pt={['20px', '40px', '40px']}>
                                <Flex justifyContent="center">
                                    <Img
                                        style={{
                                            alignSelf: 'center',
                                            width: '100%',
                                            marginBottom: '50px',
                                        }}
                                        src="https://storage.strelka.com/i/8731e932-61e0-4722-8aaa-2357823b7dd1"
                                        alt="Plan — Hypothetical organization map"
                                    />
                                </Flex>
                                <Flex mb={['60px', '325px', '325px']}>
                                    <Text
                                        fontSize={['15px', '22px', '22px']}
                                        flex="1"
                                        pr="13px"
                                    >
                                        The complexity that this model possesses
                                        in relation to society, as well as the
                                        economy, the manufacturing industries,
                                        the logistics of goods and information,
                                        and the infinite bureaucracies between
                                        them, will require the establishment of
                                        an institution capable of intervening in
                                        the regulation and coordinating of all
                                        the parties involved. This hypothetical
                                        organization must be legitimized by
                                        employing, assisting and supporting a
                                        large part of the population - while at
                                        the same time being competent, making
                                        the decisions and seeing them
                                        implemented. To do so, it must have
                                        sufficient resources and qualified
                                        personnel.
                                    </Text>
                                    <Text flex={['0', '0.1', '0.1']}> </Text>
                                    <Text
                                        fontSize={['15px', '22px', '22px']}
                                        flex="1"
                                    >
                                        To deflect the fear of big government or
                                        big corporations, it has to include
                                        mechanisms of public ownership and
                                        control. And since the metabolic
                                        malfunction that provokes climate change
                                        operates at a planetary scale, this
                                        institution must be able to operate in
                                        the same way, bypassing the
                                        jurisdictional limitation of national
                                        borders in order to guarantee proper
                                        ecosystem management and also act as a
                                        climate and security buffer against
                                        climate change-related events. Lastly,
                                        it should possess a long planning
                                        horizon, efficiently financing and
                                        conducting critical research and
                                        development of new post-fossil fuel
                                        technologies and techniques.
                                    </Text>
                                </Flex>
                                <Flex
                                    flexDirection={['column', 'row', 'row']}
                                    pb={['70px', '230px']}
                                    px={['0px', '80px', '80px']}
                                >
                                    <Flex flex="0.9">
                                        <Img
                                            style={{
                                                width: '100%',
                                                alignSelf: 'center',
                                            }}
                                            src="https://storage.strelka.com/i/c6334117-5597-4a8b-8525-d4099b769d53"
                                            alt="There currently seems to be no
                                            institution that possesses such
                                            capabilities at the scale and scope
                                            needed. However, there are several
                                            existing organisations that match
                                            some of the social and
                                            infrastructural requirements of the
                                            Green New Deal which make them
                                            potential candidates to be
                                            repurposed for such a role."
                                        />
                                    </Flex>
                                    <Flex
                                        alignSelf="flex-start"
                                        flex="1"
                                        mt={['30px', '-115px', '-115px']}
                                    >
                                        <Text
                                            fontWeight="bold"
                                            fontSize={['15px', '30px']}
                                            ml={['auto', '0px']}
                                        >
                                            There currently seems to be no
                                            institution that possesses such
                                            capabilities at the scale and scope
                                            needed. However, there are several
                                            existing organisations that match
                                            some of the social and
                                            infrastructural requirements of the
                                            Green New Deal which make them
                                            potential candidates to be
                                            repurposed for such a role.
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Div>
                        </Div>
                    </Div>
                    <Div
                        id="who-could-be-the-potential"
                        size="100%"
                        pt={['30px', '100px', '100px']}
                    >
                        <Div
                            ml={['20px', '90px', '240px']}
                            position="relative"
                            zIndex={2}
                            mr={['20px', '110px', '260px']}
                            maxWidth="1900px"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                            >
                                Candidate — Who Could Be The Potential
                            </Text>
                            <Div pt={['20px', '40px', '40px']}>
                                <Text fontSize={['15px', '22px', '22px']}>
                                    With regard to the social dimension of Green
                                    New Deals, there are many historical
                                    precedents of social security mechanisms and
                                    social protection systems, some of which are
                                    still in place today.
                                    <br />A clear example are the so-called
                                    welfare states of most developed countries,
                                    which consists of a model of sovereignty in
                                    which the State finances a number of public
                                    institutions that guarantee a set of social
                                    services to all inhabitants of the country.
                                </Text>
                            </Div>
                            <Div px={['auto', '80px', '80px']}>
                                <Flex flexDirection="row" mt="95px" mb="180px">
                                    <Flex flex="0.92">
                                        <Img
                                            style={{
                                                width: '100%',
                                                alignSelf: 'center',
                                            }}
                                            src="https://storage.strelka.com/i/7b7b4fba-752a-4748-994b-39f94d6c705e"
                                            alt="A clear example are the so-called
                                            welfare states of most developed
                                            countries, which consists of a model
                                            of sovereignty in which the state
                                            finances a number of public
                                            institutions that guarantee a set of
                                            social services to all inhabitants
                                            of the country."
                                        />
                                    </Flex>
                                    <Flex
                                        alignSelf="flex-end"
                                        flex="0.86"
                                        ml="10px"
                                        mb="-80px"
                                    >
                                        <Text
                                            ml={['10px', '45px', '45px']}
                                            fontWeight="bold"
                                            fontSize={['15px', '30px']}
                                        >
                                            A clear example are the so-called
                                            welfare states of most developed
                                            countries, which consists of a model
                                            of sovereignty in which the state
                                            finances a number of public
                                            institutions that guarantee a set of
                                            social services to all inhabitants
                                            of the country.
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Div>
                            <Text
                                display="inline-block"
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                                id="dual-power"
                            >
                                Candidate — Dual Power
                            </Text>
                            <Div pt={['20px', '40px', '40px']}>
                                <Flex justifyContent="center">
                                    <Img
                                        style={{
                                            alignSelf: 'center',
                                            width: '100%',
                                            marginBottom: '50px',
                                        }}
                                        src="https://storage.strelka.com/i/bc03bbe7-90d7-49cc-a7e1-11f58e57137a"
                                        alt="Candidate — Dual Power"
                                    />
                                </Flex>
                                <Flex mb={['60px', '325px', '325px']}>
                                    <Text
                                        fontSize={['15px', '22px', '22px']}
                                        flex="1"
                                        pr="13px"
                                    >
                                        The main limitation of the public
                                        welfare system is that it is constrained
                                        by national jurisprudence associated
                                        with an independent sovereign state.
                                        Nevertheless, there are other social
                                        structures that function internally as
                                        welfare states without responding to
                                        specific national or territorial bodies.
                                        These so-called "dual power"
                                        institutions are platforms that can
                                        coexist within other sovereign entities
                                        and become an alternative governance
                                        mechanism without formally challenging
                                        legal structures. There is a large
                                        historical catalogue of these anomalous
                                        patterns,where nations within nations
                                        work internally as socialist platforms
                                        that use their resources to the benefit
                                        of their members. In that sense, they
                                        can act as transnational welfare states.
                                    </Text>
                                    <Text flex={['0', '0.1', '0.1']}> </Text>
                                    <Text
                                        fontSize={['15px', '22px', '22px']}
                                        flex="1"
                                    >
                                        <span>
                                            Some capitalist companies may also
                                            function as welfare states,
                                            providing subsidized housing or
                                            education to their employees, or
                                            even become a socialist real estate
                                            apparatus constructing entire cities
                                            to relocate its workforce.
                                        </span>
                                        <br />
                                        <span> </span>
                                        <br />
                                        <span>
                                            Companies such as Walmart or Amazon
                                            are now planetary behemoths that
                                            operate internally as centrally
                                            planned economies, and Silicon
                                            Valley tech colossus builds entire
                                            monotowns where all the services are
                                            included for their employees.
                                        </span>
                                    </Text>
                                </Flex>
                            </Div>
                        </Div>
                    </Div>
                    <Flex
                        backgroundImage="url('https://storage.strelka.com/i/cb1ce75e-d045-4ba9-8405-fc147430ff03')"
                        backgroundSize="cover"
                        backgroundPosition="center"
                        justifyContent="center"
                        alignItems="center"
                        backgroundRepeat="no-repeat"
                    >
                        <Text
                            py="120px"
                            fontSize={['30px', '80px', '80px']}
                            fontWeight="bold"
                            maxWidth="1000px"
                            textAlign="center"
                        >
                            The first organization that offered this type of
                            social benefit is by far the biggest dual power
                            entity nowadays:
                            <br />
                            <span> </span>
                            <br />
                            <Text>THE MILITARY</Text>
                        </Text>
                    </Flex>
                    <Div
                        pt={['30px', '100px', '100px']}
                        ml={['20px', '90px', '240px']}
                        mr={['20px', '110px', '260px']}
                        maxWidth="1900px"
                    >
                        <Text
                            fontWeight="bold"
                            fontSize={['25px', '50px', '50px']}
                            id="military-welfare"
                        >
                            Candidate — Military Welfare
                        </Text>
                        <Div pt={['20px', '40px', '40px']}>
                            <Text fontSize={['15px', '22px', '22px']}>
                                Since the time when prehistoric tribes had a
                                separate caste of warriors, subsidized by others
                                to devote all their time to training and raids,
                                the military has remained a privileged stratum
                                of society. Today, the military is the last
                                Keynesian remanence in the neoliberal world. It
                                is a massive welfare state on its own. The
                                social security of the military is a series of
                                almost universal benefits for the military and
                                their families. It is a multi-billion dollar
                                apparatus whose accounts only in the US are
                                almost 50 percent of the budget of the US
                                Department of Defense.
                            </Text>
                            <Flex justifyContent="center" mb="140px">
                                <Img
                                    width={['100%', '770px', '770px']}
                                    style={{
                                        marginTop: '85px',

                                        alignSelf: 'center',
                                    }}
                                    src="https://storage.strelka.com/i/25296042-aba0-4beb-8b77-ced8443164c3"
                                    alt="Candidate — Military Welfare"
                                />
                            </Flex>
                        </Div>
                    </Div>
                    <Div
                        size="100%"
                        pt={['30px', '100px', '100px']}
                        backgroundColor="white"
                    >
                        <Div
                            ml={['20px', '90px', '240px']}
                            position="relative"
                            zIndex={2}
                            mr={['20px', '110px', '260px']}
                            maxWidth="1900px"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                                id="infrastructural-landmarks"
                            >
                                Candidate — Infrastructural Landmarks
                            </Text>
                            <Div pt={['20px', '40px', '40px']} pb="150px">
                                <Text fontSize={['15px', '22px', '22px']}>
                                    This is regarding the social security
                                    mechanism, but what about the production and
                                    infrastructure efforts that the Green New
                                    Deal implies? What are the institutional
                                    candidates capable of handling such an
                                    outstanding task? Decarbonizing ecosystems
                                    implies transforming existing industries and
                                    reorienting them towards building a new
                                    infrastructure regime. It will require an
                                    institution capable of mobilizing a huge
                                    workforce, both human and technological, but
                                    at the same time qualified to deal with
                                    tendering, contracting, budget allocation,
                                    supervision, compliance and evaluation.
                                </Text>
                                <br />
                                <span> </span>
                                <br />
                                <Text fontSize={['15px', '22px', '22px']}>
                                    Several construction companies are already
                                    doing this at one level or another.
                                    Companies such as ACS or Hochtief are
                                    megacorporations that build and develop
                                    infrastructures, both civil and industrial,
                                    and are present in hundreds of different
                                    countries.{' '}
                                </Text>
                                <Flex
                                    justifyContent="center"
                                    mb={['60px', '240px']}
                                >
                                    <Img
                                        width={['100%', '800px', '800px']}
                                        style={{
                                            marginTop: '55px',
                                            alignSelf: 'center',
                                        }}
                                        src="https://storage.strelka.com/i/b640cecf-a797-4071-8399-dab193dc2912"
                                        alt="Several construction companies are already
                                        doing this at one level or another.
                                        Companies such as ACS or Hochtief are
                                        megacorporations that build and develop
                                        infrastructures, both civil and industrial,
                                        and are present in hundreds of different
                                        countries"
                                    />
                                </Flex>
                                <Flex
                                    flexDirection="row"
                                    mt={['auto', '95px']}
                                    mb={['auto', '180px']}
                                    px={['auto', '80px', '80px']}
                                >
                                    <Flex flex="1">
                                        <Img
                                            style={{
                                                width: '100%',
                                                alignSelf: 'center',
                                            }}
                                            src="https://storage.strelka.com/i/e77259fb-b303-4fdc-ab60-289b21a6d245"
                                            alt=" Throughout history, a lot of
                                            construction has been done by
                                            military engineers and builders.
                                            From Roman legions building
                                            intercity roads, to Russian Engineer
                                            Troops at some point outnumbering
                                            the rest of the ground forces."
                                        />
                                    </Flex>
                                    <Flex
                                        alignSelf="flex-end"
                                        flex="0.86"
                                        ml="10px"
                                        mb="-95px"
                                    >
                                        <Text
                                            ml="20px"
                                            fontWeight="bold"
                                            fontSize={['15px', '30px']}
                                        >
                                            Throughout history, a lot of
                                            construction has been done by
                                            military engineers and builders.
                                            From Roman legions building
                                            intercity roads, to Russian Engineer
                                            Troops at some point outnumbering
                                            the rest of the ground forces.
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Div>
                        </Div>
                    </Div>
                    <Div
                        ml={['20px', '90px', '240px']}
                        pt={['30px', '100px', '100px']}
                        position="relative"
                        zIndex={2}
                        mr={['20px', '110px', '260px']}
                        maxWidth="1900px"
                    >
                        <Text
                            fontWeight="bold"
                            fontSize={['25px', '50px', '50px']}
                            id="military-industrial-complex"
                        >
                            Candidate — Military Industrial Complex
                        </Text>
                        <Div pt={['20px', '40px', '40px']} pb="150px">
                            <Text fontSize={['15px', '22px', '22px']}>
                                The interdependence of the industrial sector and
                                the military establishment became more
                                pronounced than ever in the 20th century when
                                the military-industrial complex was born. This
                                manufacturing device, formed by the state, the
                                Military, and several industries drove the
                                world’s most powerful economies during the cold
                                war with its ability to repurpose whole sectors
                                of the economy.
                            </Text>
                            <br />
                            <span> </span>
                            <br />
                            <Text fontSize={['15px', '22px', '22px']}>
                                The machinery of the Military-Industrial Complex
                                is today more powerful than ever. Strongly
                                privatized and diversified, it could be
                                rebranded as a Military Corporate Complex, that
                                operates worldwide as a transnational network of
                                multiple agents, constituting a multibillion
                                Research and Development mechanism, with a
                                global budget of 1.8 trillion USD, that could be
                                reoriented to more relevant goals.
                            </Text>
                            <Flex
                                justifyContent="center"
                                mb={['60px', '240px']}
                            >
                                <Img
                                    width={['100%', '800px%', '800px']}
                                    style={{
                                        marginTop: '55px',
                                        alignSelf: 'center',
                                    }}
                                    src="https://storage.strelka.com/i/db0b7b30-a8cf-441c-b036-737863b2540e"
                                    alt="house"
                                />
                            </Flex>
                            <Flex
                                flexDirection="row"
                                mt={['auto', '95px']}
                                pl={['auto', '80px', '80px']}
                            >
                                <Flex flex="1">
                                    <Img
                                        style={{
                                            width: '100%',
                                            alignSelf: 'center',
                                        }}
                                        src="https://storage.strelka.com/i/308cd139-c168-4d57-8689-9f514d104e69"
                                        alt="Interestingly, throughout history, a lot
                                        of construction has been done by
                                        military engineers and builders. From
                                        Roman legions building intercity roads,
                                        to Russian Engineer Troops at some point
                                        outnumbering the rest of the ground
                                        forces."
                                    />
                                </Flex>
                                <Flex
                                    alignSelf="flex-end"
                                    flex="0.86"
                                    ml="10px"
                                    mb="-95px"
                                >
                                    <Text
                                        ml="20px"
                                        fontWeight="bold"
                                        fontSize={['15px', '30px']}
                                    >
                                        Interestingly, throughout history, a lot
                                        of construction has been done by
                                        military engineers and builders. From
                                        Roman legions building intercity roads,
                                        to Russian Engineer Troops at some point
                                        outnumbering the rest of the ground
                                        forces.
                                    </Text>
                                </Flex>
                            </Flex>
                        </Div>
                    </Div>
                    <Div
                        size="100%"
                        pt={['30px', '100px', '100px']}
                        backgroundColor="white"
                    >
                        <Div
                            ml={['20px', '90px', '240px']}
                            position="relative"
                            zIndex={2}
                            mr={['20px', '110px', '260px']}
                            maxWidth="1900px"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                                id="sophisticated-logistics"
                            >
                                Candidate — Sophisticated Logistics
                            </Text>
                            <Div>
                                <Flex justifyContent="center">
                                    <Img
                                        style={{
                                            alignSelf: 'center',
                                            marginTop: '40px',
                                            width: '100%',
                                            marginBottom: '50px',
                                        }}
                                        src="https://storage.strelka.com/i/df99644f-6130-4f9a-b09b-158b52e2ecc0"
                                        alt="There is also one more quality that makes
                                        the military a valuable candidate for this
                                        institutional role, and this is its
                                        logistical capabilities."
                                    />
                                </Flex>
                                <Text fontSize={['15px', '22px', '22px']}>
                                    There is also one more quality that makes
                                    the military a valuable candidate for this
                                    institutional role, and this is its
                                    logistical capabilities.
                                </Text>
                                <br />
                                <span> </span>
                                <br />
                                <Text fontSize={['15px', '22px', '22px']}>
                                    Logistics is defined as the ability to
                                    organize and implement the necessary tools
                                    and techniques to achieve a particular goal.
                                    Today, they are needed in a world where
                                    flows of goods, energy and information
                                    operate on a planetary scale. Although the
                                    military logistics apparatus is larger than
                                    many other commercial logistics platforms,
                                    such as Amazon or Alibaba, It remains the
                                    most efficient because of its adaptability,
                                    flexibility and resilience, with the ability
                                    to act where logistics networks do not
                                    exist.
                                </Text>
                                <br />
                                <span> </span>
                                <br />
                                <Text fontSize={['15px', '22px', '22px']}>
                                    These complex operational skills are
                                    directly combined with the enforcement
                                    capabilities of the military to perform
                                    several tasks that any other organization is
                                    willing to undertake.
                                </Text>
                                <br />
                                <span> </span>
                                <br />
                                <Text fontSize={['15px', '22px', '22px']}>
                                    During the Chernobyl disaster on 26 April
                                    1986, it was the military over 500,000
                                    personnel and its famous liquidators and
                                    when China decided to build a large green
                                    wall to stop the Gobi Desert, where more
                                    than 60,000 soldiers were deployed to carry
                                    out the task.
                                </Text>
                                <Flex justifyContent="center">
                                    <Img
                                        style={{
                                            alignSelf: 'center',
                                            marginTop: '50px',
                                            width: '100%',
                                        }}
                                        src="https://storage.strelka.com/i/51019d7c-646e-47cc-a91d-3c8e289a40d1"
                                        alt="graph"
                                    />
                                </Flex>
                            </Div>
                        </Div>
                        <Div
                            pt={['30px', '100px', '100px']}
                            ml={['20px', '90px', '240px']}
                            position="relative"
                            zIndex={2}
                            mr={['20px', '110px', '260px']}
                            maxWidth="1900px"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                                id="operational-competencies-of-military-events"
                            >
                                Candidate — Operational competencies of military
                                events
                            </Text>
                            <Div pt={['20px', '40px', '40px']}>
                                <Text fontSize={['15px', '22px', '22px']}>
                                    Other actions such as disaster relief,
                                    humanitarian assistance and peacekeeping
                                    operations–have become a daily basis for the
                                    military, and many of them are directly or
                                    indirectly related to the implications of
                                    warming the planet.
                                </Text>
                                <br />
                                <span> </span>
                                <br />
                                <Text fontSize={['15px', '22px', '22px']}>
                                    In fact, the military is well aware of the
                                    consequences of Climate Change. The annual
                                    reports of the world's major defence
                                    agencies position climate change as one of
                                    the greatest threats.
                                </Text>
                            </Div>
                            <Div size="100%" pt="50px" backgroundColor="white">
                                <Img
                                    src="https://storage.strelka.com/i/918db651-d8c3-4379-b186-386aa71c7ad3"
                                    alt="Candidate — Operational competencies of military
                                    events Graph"
                                    style={{
                                        maxWidth: '1920px',
                                        display: 'block',
                                        width: '100%',
                                        backgroundColor: 'white',
                                    }}
                                />
                            </Div>
                        </Div>
                    </Div>
                    <Div
                        pt={['30px', '100px', '100px']}
                        ml={['20px', '90px', '240px']}
                        mr={['20px', '110px', '260px']}
                        maxWidth="1900px"
                    >
                        <Text
                            fontWeight="bold"
                            fontSize={['25px', '50px', '50px']}
                        >
                            Post-Military
                        </Text>
                        <Div>
                            <Flex justifyContent="center">
                                <Img
                                    style={{
                                        alignSelf: 'center',
                                        marginTop: '40px',
                                        width: '100%',
                                        marginBottom: '50px',
                                    }}
                                    src="https://storage.strelka.com/i/014a40fc-9c38-4be1-8528-a6b1779fe205"
                                    alt="video"
                                />
                            </Flex>
                            <Flex>
                                <Text
                                    fontSize={['15px', '22px', '22px']}
                                    flex="1"
                                >
                                    Reaching this point, knowing how the
                                    military organization can act as a social
                                    guarantee platform while deploying its
                                    infrastructural resources to operate as a
                                    highly sophisticated logistic network on a
                                    planetary scale, It would be unfair to
                                    overlook the military establishment as a
                                    potential candidate to fulfil the
                                    institutional gap created by the Green New
                                    Deal’s demands.
                                </Text>
                                <Text flex={['0', '0.1', '0.1']}> </Text>
                                <Text
                                    fontSize={['15px', '22px', '22px']}
                                    flex="1"
                                >
                                    Is clear that the redirection of the
                                    Military establishment as an institution
                                    capable of enforcing and defend an adequate
                                    metabolic transition is not only logical due
                                    to its technical and logistical capacity,
                                    but also pragmatical since it incorporates
                                    In its internal structure some of the
                                    mechanisms of social insurance and economic
                                    drive that seem essential to achieve a
                                    change in the governance of global
                                    ecologies.
                                </Text>
                            </Flex>
                            <Flex
                                mt={['40px', '120px']}
                                alignItems="center"
                                justifyContent="center"
                                mb="40px"
                                height="440px"
                            >
                                <Text
                                    fontWeight="bold"
                                    fontSize={['30px', '80px', '80px']}
                                    textAlign="center"
                                    px={['auto', '110px', '110px']}
                                >
                                    Does that mean that the military, as we know
                                    it, is the solution to the GND demands? “No”
                                </Text>
                            </Flex>
                        </Div>
                        <Div pt={['auto', '50px']} pb="80px">
                            <Text fontSize={['15px', '22px', '22px']}>
                                Does this mean that it is a good proto-platform
                                from which to build a post-military
                                establishment? The one, that, properly managed
                                and repurposed, could become a great asset to
                                enforce proper ecosystem management? This, at
                                least, seems very logical.
                            </Text>
                            <br />
                            <span> </span>
                            <br />
                            <Text fontSize={['15px', '22px', '22px']}>
                                But if that is the case, which are those points
                                addressed by the GNDs where the military should
                                be improved to become an ecological force?
                            </Text>
                        </Div>
                    </Div>
                    <Div
                        pt={['30px', '100px', '100px']}
                        pb={['30px', '150px']}
                        backgroundColor="white"
                        id="possible-scenarious"
                        maxWidth="1900px"
                    >
                        <Div
                            ml={['20px', '90px', '240px']}
                            position="relative"
                            zIndex={2}
                            mr={['20px', '110px', '260px']}
                            maxWidth="1900px"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={['25px', '50px', '50px']}
                            >
                                Post-Military — Possible scenarious
                            </Text>
                            <Div
                                pt={['20px', '40px', '40px']}
                                px={['auto', '80px', '80px']}
                            >
                                <Flex flexDirection={['column', 'row', 'row']}>
                                    <Flex flex={['1', '0.62', '0.62']}>
                                        <Img
                                            style={{
                                                width: '100%',
                                                alignSelf: 'center',
                                            }}
                                            src="https://storage.strelka.com/i/bf007442-3439-46cc-9c41-97b1e2825e9f"
                                            alt="globe"
                                        />
                                    </Flex>
                                    <Flex
                                        flex="1"
                                        ml={['auto', '20px', '20px']}
                                        flexDirection="column"
                                    >
                                        <Text
                                            pt={['40px', 'auto']}
                                            pl={['auto', '45px', '45px']}
                                            fontSize={['15px', '22px', '22px']}
                                        >
                                            Remember that the most basic premise
                                            that the GND implies is a shift in
                                            the role of the state from the
                                            governance of societies to the
                                            governance of ecosystems. And the
                                            military is part of the state, so,
                                            in that sense, the accomplishment of
                                            the GND policies implies the
                                            repurposing of the military toward
                                            ecosystem management.
                                            <br />
                                            <br />
                                            This means not only the
                                            militarization of the green but also
                                            the greenization of the military, as
                                            two processes that necessarily have
                                            to converge.
                                            <br />
                                            <br />
                                            Since it is one of the main
                                            polluters in the world, the
                                            decarbonization of the huge global
                                            military apparatus will in itself be
                                            a massive achievement, and the
                                            emergence of an industrial and
                                            logistical network capable of
                                            operating without fossil fuels will
                                            mark out the way forward.
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Div>
                        </Div>
                    </Div>
                    <Flex
                        py="120px"
                        mx={['20px', 'auto']}
                        px={['auto', '0px', '0px']}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Text
                            fontSize={['15px', '30px']}
                            fontWeight="bold"
                            maxWidth="1000px"
                        >
                            However, the military remains unmentioned. Even if
                            it seems to be already fulfilling some of the
                            characteristics of that hypothetical Institution
                            demanded by the GNDs and stands out as the most
                            reasonable candidate to address the ecological
                            emergence, there is still not a single mention of
                            the Military in any of the Green New Deals.
                            <br />
                            <br />
                            Perhaps it is time to talk about the elephant in the
                            room and confront how existing institutions could be
                            properly repurposed to address issues of global
                            necessity. If the green New Deals are just the first
                            step, probably the more logical next one would be to
                            advocate for a Green Military New Deal. A proposal
                            that in order to become feasible, should not only to
                            include the military as the relevant institution
                            that it is, but to actually enforce its repurposing
                            as the main mean through which a Green New Deal can
                            become viable.
                        </Text>
                    </Flex>
                    {/* ///////// */}
                </Div>
            </Scrollbars>
        </Tab>
    )
}

GobernanzaM.propTypes = {
    // vh: PropTypes.number,
    onRotate: PropTypes.func.isRequired,
    isActiveTab: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
}

GobernanzaM.defaultProps = {
    // vh: '',
}

export default compose(withVh)(GobernanzaM)
