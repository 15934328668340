import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Flex, RotatedText } from '../components'

const positions = [0, 100, 49]
const positionsMob = [0, 60, 29]

const TabView = styled(Flex)(({ isActiveTab, active, index }) => ({
    position: 'fixed',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
    height: '100%',
    transition: 'transform 0.3s, background-color .3s',
    transitionDelay: `0s, ${isActiveTab ? '.3s' : '0s'}`,
    transitionProperty: 'transform, background-color',
    zIndex: index,
    minWidth: '50px',
    transform: `translateY(${
        active
            ? `calc(0% + ${31 * index}px)`
            : `calc(100% - ${positionsMob[index]}px)`
    })`,

    '@media (min-width: 600px)': {
        flexDirection: 'row',
        transform: `translateX(${
            active
                ? `calc(0% + ${51 * index}px)`
                : `calc(100% - ${positions[index]}px)`
        })`,
    },
    '@media (min-width: 1300px)': {
        flexDirection: 'row',
    },
}))

const TabTitle = styled(Flex)(({ isActiveTab }) => ({
    transition: 'background-color 0.3s, color 0.3s',
    transitionDelay: `.3s, ${isActiveTab ? '.3s' : '0s'}`,
    transitionProperty: 'color, background-color',
    textTransform: 'uppercase',
    '@media (min-width: 600px)': {
        minWidth: '50px',
        borderRight: isActiveTab ? '' : '1px solid white',
        cursor: 'pointer',
        userSelect: 'none',
    },
    '@media (max-width: 600px)': {
        borderBottom: isActiveTab ? '' : '2px solid white',
        alignItems: 'center',
        height: '30px',
    },
}))

const TabContent = styled(Flex)(({ isActiveTab }) => ({
    transition: 'opacity .3s ease-in-out',
    overflow: 'hidden',
    transitionDelay: `${isActiveTab ? '.3s' : '0s'}`,
    opacity: isActiveTab ? 1 : 0,
}))

const Tab = ({ isActiveTab, active, children, index, title, onRotate }) => (
    <TabView
        isActiveTab={isActiveTab}
        active={active}
        index={index}
        bg={isActiveTab ? '#EAEAEA' : 'black'}
    >
        <TabTitle
            isActiveTab={isActiveTab}
            bg={isActiveTab ? '#EAEAEA' : 'black'}
            color={isActiveTab ? '#939393' : 'white'}
            justifyContent="center"
            onClick={() => onRotate(index)}
        >
            <RotatedText fontSize={['19px', '22px', '22px']}>
                {title}
            </RotatedText>
        </TabTitle>
        <TabContent
            size="100%"
            flexDirection="column"
            isActiveTab={isActiveTab}
            mr={['auto', '100px', '100px']}
            flex="1"
        >
            {children}
        </TabContent>
    </TabView>
)

Tab.propTypes = {
    index: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
    isActiveTab: PropTypes.bool.isRequired,
    children: PropTypes.element,
    title: PropTypes.string,
    onRotate: PropTypes.func,
}
Tab.defaultProps = {
    children: null,
    onRotate: () => {},
    title: '',
}

export default Tab
