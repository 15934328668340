/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react'

const withVh = (BaseComponent) =>
    class extends Component {
        constructor(props) {
            super(props)
            this.state = { vh: 0 }
        }

        componentDidMount() {
            this.setState({ vh: window.innerHeight * 0.01 })
        }

        render() {
            return <BaseComponent {...this.props} {...this.state} />
        }
    }

export default withVh
