import styled from '@emotion/styled'
import {
    flexbox,
    size,
    layout,
    position,
    color,
    border,
    background,
    space,
} from 'styled-system'
import Div from './Div'

const Flex = styled(Div)(
    flexbox,
    layout,
    position,
    color,
    background,
    border,
    size,
    space,
    {
        display: 'flex',
    }
)

export default Flex
