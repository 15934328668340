/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import { Flex, Text, Div, DataRow } from '../../components'
import { Tab } from '../../hocs'
import Filter from './Filter'
import data from './data'

const Repository = ({ isActiveTab, active, onRotate, index }) => {
    const [filters, setFilters] = useState([])
    const [filteredData, setFilteredData] = useState([])

    // eslint-disable-next-line no-shadow
    const handleFilterChange = ({ title, active }) => {
        const fltrs = Array.from(filters)
        if (active) {
            fltrs.push(title)
        } else {
            fltrs.splice(fltrs.indexOf(title), 1)
        }
        setFilters(fltrs)
    }
    useEffect(() => {
        const results = data.filter((item) => {
            return item.Topics.some((topic) => filters.includes(topic))
        })
        setFilteredData(results)
    }, [filters])
    return (
        <Tab
            isActiveTab={isActiveTab}
            active={active}
            index={index}
            onRotate={onRotate}
            title="RECORDS"
        >
            <Flex justifyContent="center" flex={1}>
                <Flex
                    size="100%"
                    maxWidth="1365px"
                    flex={1}
                    flexDirection="column"
                >
                    <Filter onChange={handleFilterChange} />
                    <Div size="100%" fontSize={['12px', '24px', '24px']}>
                        <Scrollbars>
                            <table
                                cellPadding="15px"
                                style={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                }}
                            >
                                <thead
                                    style={{
                                        backgroundColor: '#EAEAEA',
                                        position: 'sticky',
                                        top: 0,
                                    }}
                                >
                                    <tr>
                                        <td>
                                            <b>Type</b>
                                        </td>
                                        <td>
                                            <b>Name</b>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(filters.length === 0
                                        ? data
                                        : filteredData
                                    ).map((item, ind) => (
                                        <DataRow
                                            fontSize={['10px', '22px', '22px']}
                                            mg
                                            height="68px"
                                            key={ind}
                                        >
                                            <td>
                                                <Text>{item.Type}</Text>
                                            </td>
                                            <td>
                                                <a
                                                    rel="noopener"
                                                    target="__blank"
                                                    style={{
                                                        display: 'block',
                                                        textDecoration: 'none',
                                                        color: 'inherit',
                                                    }}
                                                    href={item.Link}
                                                >
                                                    {item.Name}
                                                </a>
                                            </td>
                                        </DataRow>
                                    ))}
                                </tbody>
                            </table>
                        </Scrollbars>
                    </Div>
                </Flex>
            </Flex>
        </Tab>
    )
}

Repository.propTypes = {
    onRotate: PropTypes.func.isRequired,
    isActiveTab: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
}
export default Repository
