import React, { useState } from 'react'
import './App.css'
import smoothscroll from 'smoothscroll-polyfill'
import { ThemeProvider } from 'emotion-theming'
import Introduction from './pages/introduction'
import GobernanzaM from './pages/gobernanza-m'
import Repository from './pages/repository'
import theme from './theme'

smoothscroll.polyfill()

function App() {
    const [boolPositions, setBoolPosittions] = useState([true, false, false])
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const handleRotateBool = (pos) => {
        const arr = boolPositions.map((poistion, index) => {
            return index <= pos
        })
        setActiveTabIndex(pos)
        setBoolPosittions(arr)
    }

    return (
        <ThemeProvider theme={theme}>
            <Introduction
                isActiveTab={activeTabIndex === 0}
                color="#EAEAEA"
                active={boolPositions[0]}
                index={0}
                onRotate={handleRotateBool}
            />
            <GobernanzaM
                isActiveTab={activeTabIndex === 1}
                color="#EAEAEA"
                active={boolPositions[1]}
                index={1}
                onRotate={handleRotateBool}
            />
            <Repository
                isActiveTab={activeTabIndex === 2}
                color="#EAEAEA"
                active={boolPositions[2]}
                index={2}
                onRotate={handleRotateBool}
            />
        </ThemeProvider>
    )
}

export default App
