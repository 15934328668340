/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { Div, Text } from '../../components'

const Filters = ({ onChange }) => {
    const [filters, toggleFilter] = useState([
        { active: false, title: 'gnd' },
        { active: false, title: 'climate change' },
        { active: false, title: 'economy' },
        { active: false, title: 'military' },
        { active: false, title: 'socialism' },
        { active: false, title: 'wealth' },
        { active: false, title: 'welfare' },
        { active: false, title: 'logistics' },
        { active: false, title: 'history' },
        { active: false, title: 'law' },
        { active: false, title: 'policy' },
        { active: false, title: 'theory' },
        { active: false, title: 'infrastructure' },
        { active: false, title: 'disaster' },
        { active: false, isBottom: true, title: 'energy' },
        { active: false, isBottom: true, title: 'industry' },
        { active: false, isBottom: true, title: 'service' },
        { active: false, isBottom: true, title: 'forecast' },
        { active: false, isBottom: true, title: 'terrorism' },
        { active: false, isBottom: true, title: 'architecture' },
        { active: false, isBottom: true, title: 'anthropocene' },
        { active: false, isBottom: true, title: 'health' },
        { active: false, isBottom: true, title: 'geography' },
        { active: false, isBottom: true, title: 'security' },
        { active: false, isBottom: true, title: 'resources' },
    ])
    const handleFilterToggle = (event) => {
        const id = event.target.dataset.filterId
        const toggled = Array.from(filters)
        toggled[id].active = !toggled[id].active
        onChange(toggled[id])
        toggleFilter(toggled)
    }
    return (
        <Div
            textAlign="center"
            fontSize={['12px', '24px', '24px']}
            my={['25px', '37px', '37px']}
        >
            <Div style={{ wordSpacing: '5px' }}>
                {filters.map(
                    (filter, index) =>
                        !filter.isBottom && (
                            <Text
                                fontWeight={filter.active ? 'bold' : 'inherit'}
                                role="button"
                                data-filter-id={index}
                                onClick={handleFilterToggle}
                                key={filter.title}
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                {`${filter.title} `}
                            </Text>
                        )
                )}
            </Div>
            <Div style={{ wordSpacing: '5px' }}>
                {filters.map(
                    (filter, index) =>
                        filter.isBottom && (
                            <Text
                                fontWeight={filter.active ? 'bold' : 'inherit'}
                                role="button"
                                data-filter-id={index}
                                onClick={handleFilterToggle}
                                key={filter.title}
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                {`${filter.title} `}
                            </Text>
                        )
                )}
            </Div>
        </Div>
    )
}

Filters.propTypes = {
    onChange: PropTypes.func.isRequired,
}

export default Filters
